
import { Autocomplete, Box, Button, Checkbox, FormControl, Icon, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { UserService } from './user.service';
import { UserDetailsModel, UserDetailsPostModel, UserModel } from '../common/models/user-account.model';
import { MessageAlert } from '../common/components/MessageAlert';
import { CommonUtils } from '../common/services/common-utils';
import { BusyIndicator } from '../common/components/BusyIndicator';
import { ComponentState } from '../common/models/component-state.model';
import { ConfirmDialog } from '../common/components/ConfirmDialog';
import AuthService from '../../services/auth.service';
import { debounce, isUndefined } from 'lodash';
import { StyledGridOverlay } from '../common/components/StyledGridOverlay';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { DropDownModel } from '../common/models/lookup.model';
import { USER_ROLES } from '../common/common.constants';
import { SharedService } from '../common/services/shared.service';
import { GrantItemModel } from '../common/models/api-response.model';

interface UserListProps {
  userRole: string;
}

const UserList: FC<UserListProps> = (props: UserListProps) => {
  const [state, setState] = React.useState<ComponentState>({ isLoading: false });
  const [usersList, setUsersList] = React.useState<GridRowsProp>([]);
  const [filteredUsersList, setFilteredUsersList] = React.useState<GridRowsProp>([]);
  const [userDistricts, setUserDistricts] = React.useState<DropDownModel[]>([]);
  const currentUser = AuthService.getCurrentUser();
  const userRoles = AuthService.getLookUp()?.roles?.filter(f => +f.roleId < (currentUser?.roleId ?? 0));

  const [reportersList, setReportersList] = useState<UserModel[]>([]);

  const [showPassword, setShowPassword] = React.useState(false);

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1, sortable: true, disableColumnMenu: true },
    { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: true, disableColumnMenu: true },
    { field: 'emailId', headerName: 'Email', flex: 1, sortable: true, disableColumnMenu: true },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, sortable: false, disableColumnMenu: true },
    {
      field: 'roleId', headerName: 'Role', flex: 1, sortable: true, disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            {USER_ROLES[params?.row?.roleId]}
          </div>
        );
      }
    },
    {
      field: 'active', headerName: 'Active', flex: 1, sortable: false, disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <Checkbox
              disabled
              key={`check-teacherSupportFlag-${params?.row?.userId}`}
              checked={params?.row?.active ?? false}
            />
          </div>
        );
      }
    }
  ];
  if ((currentUser?.roleId ?? 0) > 1) {
    columns.push({
      field: "",
      disableColumnMenu: true,
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <Button aria-label="delete" color="primary" onClick={() => handleConfirmModal(params?.row)}>
              <Icon baseClassName="fas" className="fa-pen" fontSize="small" />
            </Button>
          </div>
        );
      }
    })
  }
  const defaultRowData = {} as UserDetailsModel;
  const [rowData, setRowData] = useState<UserDetailsModel>(defaultRowData);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openConfirm2, setOpenConfirm2] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<'confirmation' | 'password'>('confirmation')
  const [userMessage, setUserMessage] = useState<string>('')

  const userService = UserService.Instance;
  const authService= AuthService.Instance;
  //for getting grants
  const sharedService = SharedService.Instance;
  const [grantsList,setGrantsList]= useState<GrantItemModel[]>([])
  const grantIdString = localStorage.getItem('grantId');
  const grantId = grantIdString !== null ? parseInt(grantIdString, 10) : 0;
  useEffect(() => {
    reloadData();
    getUserDitricts();
    const userAccount = AuthService.getCurrentUser();
    sharedService.getGrantsList(userAccount?.userId).then((res)=>{
      setGrantsList(res.data)
    })
    setRowData({ ...rowData, grantId: grantId })
  }, []);
  
  const handleConfirmModal = (rowData?: UserDetailsModel) => {
    if (!rowData) {
      rowData = defaultRowData;
      setRowData(defaultRowData);
      setOpenConfirm(true);
      return;
    }
    getuserDetails(rowData?.userId);
  }

  const handleForgotPassword = () => {
    // if (rowData.emailId) {
    //   setState({isLoading: false, messageAlert: CommonUtils.setErrorMessage('Please fill in the email field') });
    //   return;
    // }
  
    setState({ isLoading: true });
  
    authService.forgotPassword(rowData.emailId)
      .then((res) => {
        setUserMessage(res.userMessage)
        setConfirmType('password')
        setState({ isLoading: false });
      })
      .catch((err) => {
        setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
      });
  }

  const handleClose = () => {
    setRowData(defaultRowData);
    setReportersList([])
    setOpenConfirm(false);
  };

  const handleSubmit = () => {
    if (!validateMeeting()) return;
    

    rowData.active = rowData.active ?? true;
    //setting grant id directly in payload
    const postData: UserDetailsPostModel = {...rowData, assignedTo: rowData.assignedTo?.userId, grantId: grantId }
    setState({ isLoading: true });
    const promiseUser = rowData?.userId > 0 ? userService.updateUser(postData) : userService.register(postData);
    promiseUser.then(res => {
      reloadData(res?.userMessage);
      handleClose();
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    });

  };

  const reloadData = (userMessage?: string) => { 
    setState({ isLoading: true });
    userService.getUserList().then(res => {
      const rows = res?.map((m, index) => {
        return { id: index, ...m }
      });
      setUsersList(rows);
      setFilteredUsersList(rows)
      if (!isUndefined(userMessage)) {
        setState({ isLoading: false, messageAlert: CommonUtils.setAlertMessage(userMessage, "success") });
      }
      else{
        setState({ isLoading: false });
      } 
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    })
  }

  const getUserDitricts = () => {
    if (!currentUser?.userId) return;
    setState({ isLoading: true });
    userService.getUserDistrics(currentUser?.userId).then(res => {
      setUserDistricts(res);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    })
  }

  const getuserDetails = (userId: number) => {
    if ((userId ?? 0) <= 0) return;

    setState({ isLoading: true });
    userService.getUserDetails(userId).then(res => {
      setRowData(res);
      setOpenConfirm(true);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    })
  }

  const onAutoCompleteChange = (keyword: string, minRole?: number) => {
    if (!keyword) return;
    // console.log("###", keyword, minRole)
    //Dummy commit
    setState({ isLoading: true });
    userService.searchUsers(keyword, minRole).then(res => {
      setReportersList(res);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });;
    });
  }

  const onAutoCompleteInputChange = useMemo(() => debounce((keyword: string, minRole?: number) => onAutoCompleteChange(keyword, minRole), 700), []);


  const validateMeeting = (): boolean => {
    if (!rowData?.firstName || !rowData?.lastName || !rowData?.emailId || !rowData?.password || (rowData?.roleId === 0)) {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage("Please fill mandatory fields") });
      return false;
    }
    if (rowData?.districts?.length === 0) {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage("Please select atleast one district") });
      return false;
    }
    return true;
  }

  const handleDistrictsToggle = (value: number) => () => {
    if (!rowData.districts) {
      rowData.districts = [];
    }
    const currentIndex = rowData.districts.indexOf(value);
    const newChecked = [...rowData.districts];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRowData({ ...rowData, districts: newChecked });
  };
  const handleUserListSearch = (query: string) => {
    if (query) {
      const filtered = usersList.filter(user =>
        user.firstName.toLowerCase().includes(query.toLowerCase()) ||
        user.lastName.toLowerCase().includes(query.toLowerCase()) ||
        user.emailId.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsersList(filtered);
    } else {
      setFilteredUsersList(usersList);
    }
  };

  // Debounce the search input for optimized performance
  const debouncedUserListSearch = useMemo(() => debounce(handleUserListSearch, 300), [usersList]);
  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedUserListSearch.cancel();
    };
  }, []);
  return (
    <React.Fragment>
      <MessageAlert message={state.messageAlert}></MessageAlert>
      <BusyIndicator loading={state?.isLoading}></BusyIndicator>
      <header className="bg-white shadow">
        <div className="mx-auto p-2">
          <h1 className="text-xl font-bold tracking-tight text-gray-900">Member Maintenance</h1>
        </div>
      </header>

      <div className="mx-auto py-6">
        {(currentUser?.roleId ?? 0) > 1 &&
          <div className='text-right py-2'>
            <Button variant="outlined" onClick={() => handleConfirmModal()}> Add Member </Button>
          </div>
        }
        <div>
        <TextField
            id="search-user"
            label="Search Member"
            variant="outlined"
            onChange={(e) => debouncedUserListSearch(e.target.value)}
            style={{ marginBottom: '20px', width: '100%' }}
          />
          <DataGrid
            autoHeight
            rowSelection={false}
            rows={filteredUsersList}
            columns={columns}
            pageSizeOptions={[5, 10, 15, 20, 30, 50, 100]}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            slots={{
              noRowsOverlay: () => {
                return (<StyledGridOverlay message="No users"></StyledGridOverlay>)
              }
            }} />
        </div>
      </div>
      <ConfirmDialog
        maxWidth='sm'
        dialogTile={rowData?.userId > 0 ? 'Edit User' : 'Add User'}
        open={openConfirm}
        isLoading={state.isLoading}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      >
        {
          <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }} noValidate autoComplete="off">
            {openConfirm &&
              <Fragment>
                <TextField id="firstName-required" label="First Name" required value={rowData?.firstName ?? ''}
                  onChange={(val) => setRowData({ ...rowData, firstName: val.target.value })} />
                <TextField id="lastName-required" label="Last Name" required value={rowData?.lastName ?? ''}
                  onChange={(val) => setRowData({ ...rowData, lastName: val.target.value })} />
                <TextField id="emaildId-required" label="Email Id" required value={rowData?.emailId ?? ''}
                  onChange={(val) => setRowData({ ...rowData, emailId: val.target.value })} />
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    value={rowData?.password ?? ''}
                    onChange={(val) => setRowData({ ...rowData, password: val.target.value })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <TextField id="phoneNumber-required" label="Phone Number" value={rowData?.phoneNumber ?? ''} type="number"
                  onChange={(val) => setRowData({ ...rowData, phoneNumber: val.target.value })} />
                <TextField id="roleId-select-currency" label="User Role" required select value={rowData?.roleId ?? 0}
                  onChange={(val) => setRowData({ ...rowData, roleId: +val.target.value })}>
                  {userRoles?.map((option, index) => (
                    <MenuItem key={index} value={option.roleId}>
                      {option.roleName?.toUpperCase()}
                    </MenuItem>
                  ))}
                </TextField>
                
                {currentUser?.roleId!==USER_ROLES.FACILITATOR?<Autocomplete
                  id="ilt-owner"
                  noOptionsText="No Users Found"
                  options={reportersList}
                  sx={{  display: 'inline-flex' }}
                  disabled={(currentUser?.roleId === USER_ROLES.DIRECTOR || currentUser?.roleId === USER_ROLES.PROJECTLEADER || currentUser?.roleId === USER_ROLES.EVALUATOR || currentUser?.roleId===USER_ROLES.FACILITATOR)  ? false : true}
                  value={ rowData.assignedTo }
                  onInputChange={(val) => onAutoCompleteInputChange((val?.target as any)?.value, 2)}
                  renderInput={(params) => {
                    return (<TextField 
                      {...params} label="Assigned to *" 
                      value={params.inputProps.value ?? ''} 
                      helperText={rowData?.assignedTo?.emailId}
                    />)}
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setRowData({...rowData, assignedTo: value })
                  }}}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : `${option.firstName} ${option.lastName} - ${option.emailId}`
                  }
                /> :<Autocomplete
                id="ilt-owner"
                noOptionsText="No Users Found"
                options={[currentUser]}
                sx={{  display: 'inline-flex' }}
                value={ rowData.assignedTo }
                // onInputChange={(val) => onAutoCompleteInputChange((val?.target as any)?.value, 2)}
                renderInput={(params) => {
                  return (<TextField 
                    {...params} label="Assigned to *" 
                    value={params.inputProps.value ?? ''} 
                    helperText={rowData?.assignedTo?.emailId}
                  />)}
                }
                onChange={(event, value) => {
                  if (value) {
                    setRowData({...rowData, assignedTo: value })
                }}}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : `${option.firstName} ${option.lastName} - ${option.emailId}`
                }
              />}
                <TextField id="grantsList" label="Grants List" required select value={rowData?.grantId?? grantId}//from local storage
                  onChange={(val) => setRowData({ ...rowData, grantId: +val.target.value })}>
                  {grantsList?.map((grant, index) => (
                    <MenuItem key={index}  value={grant.id} disabled>
                      {grant.name?.toUpperCase()}
                    </MenuItem>
                  ))}
                </TextField>
                {rowData?.userId > 0 && <Button onClick={() => {
                  setOpenConfirm2(true);
                  setConfirmType('confirmation');
                  }}
                  className="inline-block align-baseline float-right pr-4 font-bold text-sm text-sky-700 hover:text-sky-800"
                >
                  Reset Password?
                </Button>}
                <List
                  sx={{ m: 1, flex:'1', border: '1px solid', display:'flex', flexWrap:'wrap',width:'95%',}}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader" sx={{minWidth:'100%'}}>
                      Districts 
                    </ListSubheader>
                  }>
                  
                  {userDistricts.map((option, index) => (
                    <Fragment key={`Fragment3-${index}-${option?.value}`} >
                      <ListItem key={`ListItem-${index}-${option?.value}`} className='p-1' sx={{width:'50%'}} >
                        <ListItemButton dense onClick={handleDistrictsToggle(option.value)} className='p-1'>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={(rowData?.districts?.length > 0 && rowData?.districts?.indexOf(option.value) !== -1)}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText key={`ListItemText-${index}-${option?.value}`} primary={option.label} />
                        </ListItemButton>
                      </ListItem>
                    </Fragment>
                  ))}
                </List>
                
                <Checkbox checked={rowData?.active ?? true} onChange={(e) => {
                  setRowData({ ...rowData, active: e.target.checked })
                }} /> Active
              </Fragment>
            }
          </Box>
        }
      </ConfirmDialog>
      <ConfirmDialog 
          open={openConfirm2}
          isLoading={state.isLoading}
          dialogTile="Forgot Password"
          maxWidth="lg"
          handleClose={()=> setOpenConfirm2(false)}
          handleSubmit={handleForgotPassword}
          hideSubmit={confirmType === 'password'}
          reverseBtns={confirmType === 'confirmation'}
          backAltText={ confirmType === 'confirmation' ? 'Cancel' : undefined }
          submitAltText='Reset Password'
        >
          <Box component='form' sx={{p: 5}}>
            {
              confirmType === 'confirmation' &&  <div className="mb-6">
                  <Typography className="block text-gray-700 text-sm font-bold mb-2">
                    Are you sure, you want to reset the password?
                  </Typography>
                  </div>
            }
            {
              confirmType === 'password' && 
              <div>
                <span>{userMessage}</span>
              </div>
            }
          </Box>
        </ConfirmDialog>
    </React.Fragment>
  );
};

export default UserList;