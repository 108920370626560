import { Backdrop, CircularProgress } from "@mui/material"

interface BusyIndicatorProps {
  loading: boolean;
}

export const BusyIndicator = (props: BusyIndicatorProps) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props?.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

