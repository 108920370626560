import { environment } from "../environment/environment";
import { IAPIConfirmMessage } from "../components/common/models/api-response.model";
import { HTTP_METHODS, LOCAL_STORAGE_KEYS } from "../components/common/common.constants";
import { CommonService } from "../components/common/services/common.service";
import { CommonUtils } from "../components/common/services/common-utils";
import { UserModel, UserDetailsModel, UserLogin } from "../components/common/models/user-account.model";
import { isUndefined } from "lodash";
import { LookupModel } from "../components/common/models/lookup.model";
import { getCookie } from "typescript-cookie";

export default class AuthService {

  private _baseUrl = environment.iltBaseApiUrl;
  private static _instance: AuthService;

  public static get Instance(): AuthService {
    return this._instance || (this._instance = new this());
  }

  public async login(payload: UserLogin): Promise<UserModel> {
    let result!: UserModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this._baseUrl}/login`, requestInfo)
      .then((response: Response) => {
        return CommonUtils.HttpResponse(response);
      })
      .then((response: UserModel) => {
        if (!isUndefined(response)) {
          CommonUtils.setItem<UserModel>(LOCAL_STORAGE_KEYS.USER_ACCOUNT__KEY, response);
        }
        result = response;
      });

    return result;
  }
  public async isAlreadyLoggedIn(accessToken:string){
    let result!: UserModel;
    const payload = { accessToken };
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);

    await fetch(`${this._baseUrl}/isLogged`, requestInfo)
      .then((response: Response) => {
        return CommonUtils.HttpResponse(response);
      })
      .then((response: UserModel) => {
        if (response) {
          CommonUtils.setItem<UserModel>(LOCAL_STORAGE_KEYS.USER_ACCOUNT__KEY, response);
        }
        result = response;
      })
      .catch((error) => {
        console.error('Error checking if user is logged in:', error);
      });

    return result;
  }
  public async logout() {
    const accessToken = getCookie('access_token')
    const userAccountData = sessionStorage.getItem('UserAccount');
    let userId
    if (userAccountData) {
    const userAccount = JSON.parse(userAccountData);
    userId = userAccount.userId;
    }
    let payload = {accessToken,userId}
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    let result
    await fetch(`${this._baseUrl}/logout`, requestInfo)
    .then((response: Response) => {
      return CommonUtils.HttpResponse(response);
    })
    .then((response) => {
      result=response
    })
    .catch((error) => {
      console.error('error while loggingout', error);
    });
    return result
  }

  public async forgotPassword(emailId: string): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST)
    const userAccountData = sessionStorage.getItem('UserAccount');
    let userId
    if (userAccountData) {
    const userAccount = JSON.parse(userAccountData);
    userId = userAccount.userId;
    }
    await fetch(`${this._baseUrl}/users/${userId}/${emailId}/reset/password`, requestInfo)
      .then((response: Response) => {
        return CommonUtils.HttpResponse(response);
      })
      .then((response: IAPIConfirmMessage) => {
        result = response;
      });
    return result
  }

  public async register(payload: UserDetailsModel): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this._baseUrl}/login`, requestInfo)
      .then((response: Response) => {
        return CommonUtils.HttpResponse(response);
      })
      .then((response: IAPIConfirmMessage) => {
        result = response;
      });

    return result;
  }

  public async lookUpData(): Promise<LookupModel> {
    let result!: LookupModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this._baseUrl}/shared/lookup`, requestInfo)
      .then((response: Response) => {
        return CommonUtils.HttpResponse(response);
      })
      .then((response: LookupModel) => {
        if (!isUndefined(response)) {
          CommonUtils.setItem<LookupModel>(LOCAL_STORAGE_KEYS.LOOK_UP_KEY, response);
        }
        result = response;
      });

    return result;
  }

  public static getCurrentUser() {
    const userStr = CommonUtils.getItem<UserModel>(LOCAL_STORAGE_KEYS.USER_ACCOUNT__KEY);
    return userStr;
  }

  public static getLookUp() {
    const result = CommonUtils.getItem<LookupModel>(LOCAL_STORAGE_KEYS.LOOK_UP_KEY);
    return result;
  }
}