import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// this is rendering the app
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
reportWebVitals();
