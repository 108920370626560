import { get, isEmpty, isNull, isUndefined } from "lodash";
import { IAPIConfirmMessage } from "../models/api-response.model";
import { AlertStatus, IMessageAlertModel } from "../models/message-alert.model";
import { ALERT_STATUS } from "../common.constants";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export class CommonUtils {

  public static getEnumKeys<T>(enumToDeconstruct: T): Array<keyof typeof enumToDeconstruct> {
    return Object.keys(enumToDeconstruct as any) as Array<keyof typeof enumToDeconstruct>;
  };

  public static setItem<T>(key: string, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public static removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  public static getItem<T>(key: string): T | undefined {
    const storedValue = sessionStorage.getItem(key);
    if (!isNull(storedValue)) {
      return JSON.parse(storedValue) as T;
    }
    return undefined;
  }

  public static HttpResponse(response: Response, returnMessage = false) {
    const contentLength = response.headers?.get("Content-Length");
    if (contentLength === "0") {
      return CommonUtils.readErrorMessage(response);
    }
    return response.json()
      .then(resBody => {
        const message = CommonUtils.readErrorMessage(response, resBody);
        return returnMessage ? message : resBody;
      });
  }

  public static HttpFileResponse(response: Response, returnMessage = false) {
    const contentLength = response.headers?.get("Content-Length");
    if (contentLength === "0") {
      return CommonUtils.readErrorMessage(response);
    }
    const contentType = response.headers?.get("Content-Type")?.split(';')[0];
    if (contentType === 'application/json' || contentType === 'text/plain') {
      return response.json()
        .then(resBody => {
          const message = CommonUtils.readErrorMessage(response, resBody);
          return returnMessage ? message : resBody;
        });
    }
    return response.blob();
  }


  public static readErrorMessage(response: Response, resBody?: any): string {
    const userMessage = CommonUtils.readConfirmMessage(resBody?.confirmMessage ?? resBody, response.status) ?? resBody?.detail ?? resBody?.errorMsg ?? resBody ?? response.statusText;
    if (response.status === 200) {
      return userMessage;
    }
    let errorResponse = "The application has encountered an error.";
    switch (response.status) {
      case 412: {
        errorResponse = `Precondition failed ${userMessage}`;
        window.location.href = window.location.origin;
        break;
      }
      case 301:
      case 302: {
        errorResponse = `Session timed out, please login again. ${userMessage}`;
        window.location.href = window.location.origin;
        break;
      }
      default: {
        errorResponse = `${userMessage}`;
        break;
      }
    }
    throw new Error(errorResponse);
  }

  public static formatISODateTime(dateString: string, formatType: number) {
    if (!dateString) {
      return '-';
    }

    // Convert the string to UTC using Day.js
    const utcDate = dayjs.utc(dateString);

    if (!utcDate.isValid()) {
      return '-'; // Invalid date
    }

    // Convert the UTC date to the user's local timezone
    const timeZone = dayjs.tz.guess();
    const localDate = utcDate.tz(timeZone);

    let formattedDate;
    switch (formatType) {
      case 1:
        formattedDate = localDate.format('YYYY-MM-DD - HH:mm')
        break;
      case 2:
        formattedDate = localDate.format('MMM DD - hh:mm A')
        break;
      case 3:
        formattedDate = localDate.format('MMM DD ')
        break;
      default:
        formattedDate = localDate.format('MMM DD - hh:mm A')
    };
    return formattedDate;
  }

  public static setErrorMessage(err: any) {
    let errorMessage = err.message ?? err.errorMsg ?? err;
    if (errorMessage === "Failed to fetch") { errorMessage = "Please Reload"; }
    return CommonUtils.setAlertMessage(errorMessage, "error");
  }

  public static setAlertMessage(alertMsg: string, alertStatus: AlertStatus) {
    if (!isEmpty(alertMsg)) {
      const messageAlert: IMessageAlertModel = {
        message: alertMsg,
        status: alertStatus
      }
      return messageAlert;
    }
  }


  private static readConfirmMessage(confirmMessage: IAPIConfirmMessage, status: number): string | undefined {
    let userMessage: string | undefined = get(confirmMessage, 'userMessage') ?? get(confirmMessage, 'UserMessage.Message') ?? get(confirmMessage, 'error');
    const errorId = get(confirmMessage, 'confirmMessageID') ?? get(confirmMessage, 'TaaSID');
    if (!isEmpty(errorId) && status !== 200) {
      userMessage = `${userMessage}. ErrorId: ${errorId}`;
    }
    return userMessage;
  }
}