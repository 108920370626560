import { environment } from "../../environment/environment";
import { IAPIConfirmMessage } from "../common/models/api-response.model";
import { HTTP_METHODS } from "../common/common.constants";
import { CommonService } from "../common/services/common.service";
import { UserModel, UserDetailsModel, UserLogin, ChangePasswordModel, UserDetailsPostModel } from "../common/models/user-account.model";
import { DropDownModel } from "../common/models/lookup.model";
import { parse } from "path";
import {getCookie} from "typescript-cookie";

export class UserService {
  private _baseUrl = environment.iltBaseApiUrl;
  private serviceURL = `${this._baseUrl}/users`;
  private static _instance: UserService;

  public static get Instance(): UserService {
    return this._instance || (this._instance = new this());
  }

  async register(payload: UserLogin): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken);
    await fetch(`${this.serviceURL}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async updateUser(payload: UserDetailsPostModel): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken);
    await fetch(`${this.serviceURL}/${payload.userId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async updatePassword(payload: ChangePasswordModel, userId: number): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken)
    await fetch(`${this.serviceURL}/${userId}/password`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpFileResponse(response);
      })
      .then((response: any) => {
        result = response;
      })
    return result
  }

  async searchUsers(keyword: string, minRole?: number): Promise<UserModel[]> {
    let result!: UserModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    const url = `${this.serviceURL}?keyword=${keyword}${minRole ? `&minRole=${minRole}` : ''}`;
    await fetch(url, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response ?? [];
      });
    return result;
  }

  async getUserList(): Promise<UserDetailsModel[]> {
    let result!: UserDetailsModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: UserDetailsModel[]) => {
        result = response;
      });
    return result;
  }

  async getUserDetails(userId: number): Promise<UserDetailsModel> {
    let result!: UserDetailsModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}/${userId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: UserDetailsModel) => {
        result = response;
      });
    return result;
  }

  async getUserDistrics(userId: number): Promise<DropDownModel[]> {
    let result!: DropDownModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}/${userId}/districts`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: DropDownModel[]) => {
        result = response;
      });
    return result;
  }

  async getUserDistricSchools(districtId: number): Promise<DropDownModel[]> {
    let result!: DropDownModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    const userData = JSON.parse(sessionStorage.getItem('UserAccount')??'');
    const userId = userData?.userId
    await fetch(`${this.serviceURL}/${userId}/districts/${districtId}/schools`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: DropDownModel[]) => {
        result = response;
      });
    return result;
  }
}
