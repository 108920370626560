import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material"
import { TransitionProps } from '@mui/material/transitions';
import React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ConfirmDialogProps {
  children?: string | JSX.Element | JSX.Element[];
  open: boolean;
  dialogTile: string;
  maxWidth: 'sm' | 'md' | 'lg';
  isLoading: boolean;
  handleClose: any;
  handleSubmit?: any;
  hideSubmit?: boolean;
  fullWidth?: boolean;
  fullScreen?: boolean;
  reverseBtns?: boolean;
  backAltText?: string;
  submitAltText?: string;
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <main>
      <Dialog open={props.open}
        maxWidth={props.maxWidth}
        onClose={props.handleClose}
        fullWidth={props.fullWidth}
        fullScreen={props.fullScreen}
        TransitionComponent={Transition}>
        <DialogTitle>{props.dialogTile}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions sx={{display: 'flex', flexDirection: 'row', alignItems: "flex-end"}}>
          <div className={`flex ${props.reverseBtns ? 'flex-row-reverse' : 'flex-row' }  justify-between`}>
          <Button variant="outlined" 
            sx={{
              color:"rgba(244, 108, 115, 1)", fontWeight:'bold', borderColor: "rgba(244, 108, 115, 1)",
            ':hover': { borderColor: "rgba(244, 108, 115, 1)", bgcolor: 'rgba(244, 108, 115, 1)', color: '#fff' }
            }}
            onClick={props.handleClose}
          >
            {props.backAltText ?? 'Back'}
          </Button>
          {!props.hideSubmit && 
            <Button variant="contained" 
              sx={{marginInline: '10px'}} 
              onClick={props.handleSubmit} 
              disabled={props.isLoading}
              style={{cursor: props.isLoading ? 'progress' : 'pointer'}}
            >
              {props.submitAltText ?? 'Submit'}
            </Button>}
          </div>
        </DialogActions>
      </Dialog>
    </main>
  )
}

