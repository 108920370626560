import AppRoutes from './routes/app-routes';
import { Link, Tooltip } from "@mui/material";
import ReportIcon from '@mui/icons-material/Report';
import {Toaster} from 'react-hot-toast'

const App = () => {
  return (
    <div className="min-h-full">
      <Toaster position='bottom-center'/>
      <AppRoutes />
      <Tooltip title="Report any issue">
        <Link 
          href="https://docs.google.com/forms/d/e/1FAIpQLSfy0QNRpunTe2ftuH6JcMWntHV3if-cROBQkB8r0oEgwiAztw/viewform?usp=sf_link"
          target="_blank" 
          rel="noopener noreferrer"
          sx={{position: 'fixed', bottom: 20, right: 20}}
        >
          <ReportIcon sx={{color: '#F46C73', width: 30, height: 30, boxShadow: 2, borderRadius: '50%'}} />
        </Link>
      </Tooltip>
    </div>
  );
}
export default App;
