import { environment } from "../../../environment/environment";
import { IAPIConfirmMessage, IAPIResponseModel } from "../../common/models/api-response.model";
import { HTTP_METHODS } from "../../common/common.constants";
import { CommonService } from "../../common/services/common.service";
import { ILTModel, ILTDetailsModel, ILTPostModel, DirSchoolModel } from "../models/ilt.model";
import { ILTDashboardModel } from "../models/ilt-meeting.model";
import { getCookie } from "typescript-cookie";

export class ILTService {
  private _baseUrl = environment.iltBaseApiUrl;
  private serviceURL = `${this._baseUrl}/ilts`;
  private static _instance: ILTService;

  public static get Instance(): ILTService {
    return this._instance || (this._instance = new this());
  }

  async createILT(payload: ILTPostModel): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken);
    await fetch(`${this.serviceURL}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async updateILT(payload: ILTPostModel): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken);
    await fetch(`${this.serviceURL}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getILTList(): Promise<ILTModel[]> {
    let result!: ILTModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getSchoolDetails(userId: number, payload: any): Promise<DirSchoolModel> {
    let result!: DirSchoolModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken);
    await fetch(`${this._baseUrl}/dashboard/userId/${userId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getDistrictDetails(userId: number, payload: any): Promise<DirSchoolModel> {
    let result!: DirSchoolModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken);
    await fetch(`${this._baseUrl}/dashboard/userId/${userId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getILTDetails(iltId: number): Promise<ILTDetailsModel> {
    let result!: ILTDetailsModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}/${iltId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getILTDashboard(iltId: number, payload?: any): Promise<ILTDashboardModel> {
    let result!: ILTDashboardModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload,accessToken);
    await fetch(`${this._baseUrl}/dashboard/ilt/${iltId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

}
