import { IAPIConfirmMessage } from "../models/api-response.model";
import { COMMON_HEADERS } from "../common.constants";
import { get, isEmpty, isUndefined } from 'lodash';
import AuthService from "../../../services/auth.service";
import dayjs from "dayjs";
import {  removeCookie } from "typescript-cookie";

export class CommonService {

  public static FormatDate(newValue: dayjs.Dayjs | null) {
    return newValue?.format('YYYY-MM-DDTHH:mm:ss');
  }

  public static RequestInfo(httpMethod: string, payload?: any,accessToken?:string) {
    const requestInfo = {
      method: httpMethod,
      "Access-Control-Allow-Origin": "*"
    } as RequestInit;
    requestInfo.method = httpMethod;

    if (!isUndefined(payload)) {
      requestInfo.body = JSON.stringify(payload);
    }

    const userAccount = AuthService.getCurrentUser();
    if (!isUndefined(userAccount)) {
      COMMON_HEADERS['UserId'] = userAccount.userId;
    }
    if(accessToken){
      COMMON_HEADERS['access-token'] = `${accessToken}`;
    }
    requestInfo.headers = COMMON_HEADERS;
    return requestInfo;
  }

  public static HttpResponse(response: Response, returnMessage = false) {
    const contentLength = response.headers?.get("Content-Length");
    if (contentLength === "0") {
      return CommonService.readErrorMessage(response);
    }
    return response.json()
      .then(resBody => {
        const message = CommonService.readErrorMessage(response, resBody);
        return returnMessage ? message : resBody;
      });
  }

  public static HttpFileResponse(response: Response, returnMessage = false) {
    const contentLength = response.headers?.get("Content-Length");
    if (contentLength === "0") {
      return CommonService.readErrorMessage(response);
    }
    const contentType = response.headers?.get("Content-Type")?.split(';')[0];
    if (contentType === 'application/json' || contentType === 'text/plain') {
      return response.json()
        .then(resBody => {
          const message = CommonService.readErrorMessage(response, resBody);
          return returnMessage ? message : resBody;
        });
    }
    return response.blob();
  }


  public static readErrorMessage(response: Response, resBody?: any): string {
    const userMessage = CommonService.readConfirmMessage(resBody?.confirmMessage ?? resBody, response.status) ?? resBody?.errorMsg ?? resBody ?? response.statusText;
    if (response.status === 200) {
      return userMessage;
    }

    let errorResponse = "The application has encountered an error.";
    switch (response.status) {
      case 412: {
        errorResponse = `Precondition failed ${userMessage}`;
        window.location.href = window.location.origin;
        break;
      }
      case 301:
      case 302: {
        errorResponse = `Session timed out, please login again. ${userMessage}`;
        window.location.href = window.location.origin;
        break;
      }
      case 401:{
        errorResponse=`Session expired, please login`
        sessionStorage.clear()
        removeCookie('access_token', { path: '/' })
        window.location.href = '/login';
        break;
      }
      default: {
        errorResponse = `${userMessage}`;
        break;
      }
    }
    throw new Error(errorResponse);
  }

  private static readConfirmMessage(confirmMessage: IAPIConfirmMessage, status: number): string | undefined {
    if (Array.isArray(confirmMessage?.userMessage)) {
      const entityError: string[] = get(confirmMessage, 'userMessage[0].loc') ?? [];
      const entityMsg = get(confirmMessage, 'userMessage[0].msg');
      return `${entityError?.toString()}, ${entityMsg}`;
    }
    let userMessage: string | undefined = get(confirmMessage, 'userMessage') ?? get(confirmMessage, 'UserMessage.Message') ?? get(confirmMessage, 'error');
    const errorId = get(confirmMessage, 'confirmMessageID') ?? get(confirmMessage, 'TaaSID');
    if (!isEmpty(errorId) && status !== 200) {
      userMessage = `${userMessage}. ErrorId: ${errorId}`;
    }
    return userMessage;
  }
}