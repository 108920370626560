
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "../../../services/auth.service";

export const AuthLayout = () => {
  const authed = AuthService.getCurrentUser();
  return (
    <main>
      {(authed?.userId ?? 0) ?
        <Navigate to="/dashboard" />
        :
        <main>
          <div className="grid grid-cols-2 gap-3 place-items-center h-screen">
            <div className="col-span-1 inline-flex">
              <img
                className="h-44 w-44"
                src={process.env.PUBLIC_URL + "/ilt-logo.png"}
                alt="LIT"
              />
              <div className="pt-16 pl-5 text-5xl text-sky-800">
                Middle LLC
              </div>
            </div>
            <div className="col-span-1">
              <Outlet></Outlet>
            </div>
          </div>
        </main>}
    </main>
  )
}