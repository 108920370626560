
import { Box, Button, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { UserService } from './user.service';
import { ChangePasswordModel, UserDetailsModel, UserDetailsPostModel, UserModel } from '../common/models/user-account.model';
import { MessageAlert } from '../common/components/MessageAlert';
import { CommonUtils } from '../common/services/common-utils';
import { BusyIndicator } from '../common/components/BusyIndicator';
import { ComponentState } from '../common/models/component-state.model';
import { isUndefined } from 'lodash';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuthService from '../../services/auth.service';
import { LOCAL_STORAGE_KEYS } from '../common/common.constants';
import { ConfirmDialog } from '../common/components/ConfirmDialog';
import toast from 'react-hot-toast';

interface UserProfileProps {
}

const UserProfile: FC<UserProfileProps> = (props: UserProfileProps) => {
    const [state, setState] = React.useState<ComponentState>({ isLoading: false });
    const [showPassword, setShowPassword] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const defaultRowData = {} as UserDetailsModel;
    const [rowData, setRowData] = useState<UserDetailsModel>(defaultRowData);
    const [passwords, setPasswords] = useState<ChangePasswordModel>({} as ChangePasswordModel)
    const currentUser = AuthService.getCurrentUser();
    const userService = UserService.Instance;
    useEffect(() => {
        reloadData();
    }, []);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }

    const handleSubmit = () => {
        if (!validateUseDetails()) return;

        // setState({ isLoading: true });
        const payload = {
            userId: rowData.userId,
            firstName: rowData.firstName,
            lastName: rowData.lastName,
            emailId: rowData.emailId,
            phoneNumber: rowData.phoneNumber,
            active: true,
            roleId: rowData.roleId,
            districts: rowData.districts,
            assignedTo: rowData.assignedTo?.userId
        } as UserDetailsPostModel;

        userService.updateUser(payload).then(res => {
            reloadData(res?.userMessage,true);
            if (currentUser) {
                currentUser.firstName = payload.firstName;
                currentUser.lastName = payload.lastName;
                CommonUtils.setItem<UserModel>(LOCAL_STORAGE_KEYS.USER_ACCOUNT__KEY, currentUser);
            }
        })
        .catch((err) => {
            console.log(err)
            setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
        });
    };

    const handleChangePassword = () => {
        if (!validateUseDetails()) return;

        setState({isLoading: true})
        userService.updatePassword(passwords, rowData.userId).then(res => {
            setOpenConfirm(false);
            setPasswords({} as ChangePasswordModel)
            setState({ isLoading: false, messageAlert: CommonUtils.setAlertMessage(res?.userMessage, "success")})
        }).catch((err) => {
            setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
        });
    }

    const reloadData = (userMessage?: string,isUpdated?:boolean) => {
        if (!isUndefined(userMessage)) {
            setState({ isLoading: false, messageAlert: CommonUtils.setAlertMessage(userMessage, "success") });
        }

        if (!currentUser?.userId) return;

        setState({ isLoading: true });
        userService.getUserDetails(currentUser.userId).then(res => {
            setRowData(res);
            setState({ isLoading: false });
            if(isUpdated){
                setState({isLoading:false,messageAlert:{message:'Updated Successfully',status:"success"}})
            }
        }).catch((err) => {
            console.log(err)
            setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
        })
    }

    const validateUseDetails = (): boolean => {
        if (openConfirm && (!passwords?.oldPassword || !passwords?.newPassword)) {
            setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage("Please fill both password fields") });
            return false;
        }
        if (!rowData?.firstName || !rowData?.lastName) {
            setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage("Please fill the First Name / Last Name fields") });
            return false;
        }
        return true;
    }

    return (
        <React.Fragment>
            <MessageAlert message={state.messageAlert}></MessageAlert>
            <BusyIndicator loading={state?.isLoading}></BusyIndicator>
            <header className="bg-white shadow">
                <div className="mx-auto py-6 px-4">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Member Profile</h1>
                </div>
            </header>
            <Container maxWidth="sm" className='pt-5'>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: 250 }, }} noValidate autoComplete="off">
                    <Fragment>
                        <TextField id="firstName-required" label="First Name" required value={rowData?.firstName ?? ''}
                            onChange={(val) => setRowData({ ...rowData, firstName: val.target.value })} />
                        <TextField id="lastName-required" label="Last Name" required value={rowData?.lastName ?? ''}
                            onChange={(val) => setRowData({ ...rowData, lastName: val.target.value })} />
                        <TextField id="emaildId-required" disabled label="Email Id" required value={rowData?.emailId ?? ''}
                            onChange={(val) => setRowData({ ...rowData, emailId: val.target.value })} />
                        <TextField id="phoneNumber-required" label="Phone Number" value={rowData?.phoneNumber ?? ''} type="number"
                            onChange={(val) => setRowData({ ...rowData, phoneNumber: val.target.value })} />
                    </Fragment>
                </Box>
                <div className='flex justify-between py-2 pr-6'>
                    <Button onClick={handleOpenConfirm} >Change Password?</Button>
                    <Button variant="outlined" onClick={() => handleSubmit()}> Save </Button>
                </div>
                <ConfirmDialog
                open={openConfirm}
                isLoading={state.isLoading}
                dialogTile='Change Password'
                maxWidth='lg'
                handleClose={handleCloseConfirm}
                handleSubmit={handleChangePassword}
                >
                    {<Box component="form" sx={{display: 'flex', flexDirection: 'column'}}>
                        <FormControl sx={{ m: 2, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-old-password">Enter Old Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-old-password"
                                type={showPassword ? 'text' : 'password'}
                                required
                                value={passwords?.oldPassword ?? ''}
                                onChange={(val) => setPasswords({ ...passwords, oldPassword: val.target.value })}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((show) => !show)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Enter Old Password"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 2, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-new-password"
                                type={showPassword ? 'text' : 'password'}
                                required
                                value={passwords?.newPassword ?? ''}
                                onChange={(val) => setPasswords({ ...passwords, newPassword: val.target.value })}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((show) => !show)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="New Password"
                            />
                        </FormControl>
                    </Box>}
                </ConfirmDialog>
            </Container>
        </React.Fragment>
    );
};

export default UserProfile;