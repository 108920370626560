import { Paper, styled } from "@mui/material"

export const CONFIG_LABELS = {
  REQUEST_SUCCESS: "Request processed successfully"
}

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}

export const COMMON_HEADERS: any = {
  'Content-Type': 'application/json'
}

export const ALERT_STATUS = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  NEUTRAL: 'neutral'
}

export const LOCAL_STORAGE_KEYS = {
  USER_ACCOUNT__KEY: "UserAccount",
  LOOK_UP_KEY: 'LookUp'
}

export const StackItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export enum MEETING_STATUS {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED
}

export enum DIALOG_TYPE {
  START_CONFIRMATION,
  END_CONFIRMATION,
  MEETING_SCHEDULE,
  MEETING_TRANSFER
}

export enum USER_ROLES {
  MEMBER = 1,
  FACILITATOR = 2,
  PROJECTLEADER = 3,
  DIRECTOR = 4,
  EVALUATOR = 5
}

export const PRIORITIES = [
  {
    label: 'High',
    value: 1,
  },
  {
    label: 'Medium',
    value: 2,
  },
  {
    label: 'Low',
    value: 3
  },
  {
    label: 'No Longer Required',
    value: 4
  }
]
