import { getCookie } from "typescript-cookie";
import { environment } from "../../../environment/environment";
import { ILTModel, ILTPostModel } from "../../ilts/models/ilt.model";
import { HTTP_METHODS } from "../common.constants";
import { GrantModel, IAPIConfirmMessage } from "../models/api-response.model";
import { LookupModel } from "../models/lookup.model";
import { PriorityModel } from "../models/priority.model";
import { RolesModel } from "../models/roles.model";
import { SchoolsModel } from "../models/schools.model";
import { CommonService } from "./common.service";

export class SharedService {
  private _baseUrl = environment.iltBaseApiUrl;
  private serviceURL = `${this._baseUrl}/shared`;
  private static _instance: SharedService;

  public static get Instance(): SharedService {
    return this._instance || (this._instance = new this());
  }

  async getLookup(): Promise<LookupModel> {
    let result!: LookupModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}/lookup`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getPriorities(): Promise<PriorityModel[]> {
    let result!: PriorityModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}/priorities`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getSchools(): Promise<SchoolsModel[]> {
    let result!: SchoolsModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}/schools`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getRoles(): Promise<RolesModel[]> {
    let result!: RolesModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this.serviceURL}/roles`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getDistrictLTs (districtId: number): Promise<ILTModel[]> {
    let result!: ILTModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this._baseUrl}/users/districts/${districtId}/ilts`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: ILTModel[]) => {
        result = response;
      });
    return result;
  }

  async getSchoolILTs (schoolId: number): Promise<ILTModel[]> {
    let result!: ILTModel[];
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    const userData = JSON.parse(sessionStorage.getItem('UserAccount')??'');
    const userId = userData?.userId
    await fetch(`${this.serviceURL}/${userId}/schools/${schoolId}/ilts`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: ILTModel[]) => {
        result = response;
      });
    return result;
  }

  //grant apis
  async getGrantsList (userId:any): Promise<GrantModel> {
    let result!: GrantModel;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET,undefined,accessToken);
    await fetch(`${this._baseUrl}/users/getListOfGrants/${userId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: GrantModel) => {
        result = response;
      })
      .catch((err)=>{
        console.log(err)
      }
      )
    return result;
  }
  async sendUserSGrantId (id: number): Promise<IAPIConfirmMessage> {
    let result!: IAPIConfirmMessage;
    const accessToken= getCookie('access_token')
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.PATCH,{grant_id: id},accessToken);
    await fetch(`${this._baseUrl}/users/updateUserGrantIdForEvaluator`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: IAPIConfirmMessage) => {
        result = response;
      });
    return result;
  }

  
} 


