import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { ComponentState, useEffect, useState } from "react";
import AuthService from "../../../services/auth.service";
import { CommonUtils } from "../services/common-utils";
import { LOCAL_STORAGE_KEYS } from "../common.constants";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  MenuList,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Logout, PersonAdd } from "@mui/icons-material";
import { Disclosure } from "@headlessui/react";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

import { styled, alpha } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GrantItemModel } from "../models/api-response.model";
import { SharedService } from "../services/shared.service";
import {removeCookie ,getCookie} from 'typescript-cookie'
import { setsEqual } from "chart.js/dist/helpers/helpers.core";
import { BusyIndicator } from "./BusyIndicator";
import authChannel from "../../../Broadcast";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgb(17,24,39)',
  },
  '& .MuiButton-label': {
    textTransform: 'lowercase',
  },
}));


const navigation = [
  { name: "Dashboard", href: "dashboard", current: true },
  { name: "Meetings", href: "meetings", current: false },
  { name: "Calendar", href: "calendar", current: false },
];

const classNames = (classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const MainLayout = () => {
  const [state, setState] = React.useState<ComponentState>({ isLoading: false });
  const authed = AuthService.getCurrentUser();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const pathName = location?.pathname?.replace("/", "");
  if (pathName) {
    navigation.map((f) => {
      return (f.current = f.href === pathName ? true : false);
    });
  }
  const sharedService = SharedService.Instance
  const handleNavigation = (href: string) => {
    handleClose();
    setState({ isLoading: true });
    if (href === "login") {
      localStorage.removeItem("selectedILT");
      //removing grant info after loggingout
      localStorage.removeItem('grantName')
      localStorage.removeItem('grantId')
      localStorage.removeItem('meetingRoomName')
      //removing the token for other user to login
      removeCookie('access_token', { path: '/' })
      CommonUtils.removeItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT__KEY);
      CommonUtils.removeItem(LOCAL_STORAGE_KEYS.LOOK_UP_KEY);
      sessionStorage.removeItem('selectedILT')
      setState({ isLoading: false });
    }
    setState({ isLoading: false });
    navigate(`/${href}`);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout=async()=>{
    setState({ isLoading: true });
    const res = await AuthService.Instance.logout()
    authChannel.postMessage({type:'logout'})
    handleNavigation('login')
  }
  //grants all info
  const [anchorElGrant, setAnchorElGrant] = React.useState<null | HTMLElement>(
    null
  );
  
  const [grantData,setGrants]= useState<GrantItemModel[]>([])
  const [currentGrant,setCurrentGrant] = useState<string>('')
  const openGrant = Boolean(anchorElGrant);
  const handleClickGrant = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElGrant(event.currentTarget);
  };
  function handleNavigateHome(){
    navigate('/dashboard')
  }
  const handleCloseGrant = (id:number,grant:string) => {
    sharedService.sendUserSGrantId(id).then(()=>{
      localStorage.setItem('grantName',grant)
      localStorage.setItem('grantId',id.toString())
      setCurrentGrant(grant)
      window.location.reload();
    })
    setAnchorElGrant(null);
  };
  const handleSimpleCloseGrant = () => {
    setAnchorElGrant(null);
  };

  useEffect(()=>{
    if(!getCookie('access_token')){
      handleNavigation('login')
    }
    const userAccount = AuthService.getCurrentUser();
    sharedService.getGrantsList(userAccount?.userId).then((res)=>{
      setGrants(res.data)
      return res
    }).then((res)=>{
      const grantIdString = localStorage.getItem('grantId');
      const grantId = grantIdString !== null ? parseInt(grantIdString, 10) : 0;
      let grant = res.data.filter((element,idx)=>element.id === grantId)
      localStorage.setItem('grantName',grant[0].name)
      const localGrant= localStorage.getItem('grantName')
      setCurrentGrant(localGrant !== null && localGrant !== undefined ? localGrant : (res.data[0]?.name))
    }).catch((err)=>{
      console.log(err)
    })
  },[]) 
  authChannel.onmessage = async(e)=>{
    if(e.data.type==='logout'){
      handleNavigation('login')
    }
  }
  useEffect(() => {
    handleClose(); // Ensure dropdown is closed on navigation
  }, [location]);
  return (
    <main>
      {authed?.userId ?? 0 ? (
        <React.Fragment>
        
        <BusyIndicator loading={state?.isLoading}></BusyIndicator>
          <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <React.Fragment>
                <div className="mx-auto px-4">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          onClick={handleNavigateHome}
                          className="h-12 w-12"
                          src={process.env.PUBLIC_URL + "/ilt-logo.png"}
                          alt="LIT"
                        />
                      </div>

                      {grantData?.length===1&&<div style={{marginLeft:'2rem',paddingLeft:'1rem',color:"white",fontSize:'0.9rem'}}>
                        <p>{currentGrant}</p>
                      </div>}

                      {grantData?.length>1&&<div>
                        <ColorButton
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleClickGrant}
                          endIcon={<KeyboardArrowDownIcon />}
                          sx={{backgroundColor:'rgb(31,41,55)',marginLeft:'2rem',textTransform:'none'}}
                        >
                          {currentGrant}
                        </ColorButton>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorElGrant}
                          open={openGrant}
                          onClose={handleSimpleCloseGrant} 
                        >
                          
                          {grantData.length>1&&grantData.map((obj)=>(
                            <MenuItem key={obj?.id} onClick={()=>handleCloseGrant(obj?.id,obj?.name)}>{obj?.name}</MenuItem>
                          ))}
                          
                        </StyledMenu>
                      </div>}
                      
                    </div>
                    <div className="inline-flex">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames([
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "rounded-md px-3 py-2 text-sm font-medium",
                            ])}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                      <div className="ml-4 flex items-center md:ml-6">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={openMenu ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openMenu ? "true" : undefined}
                        >
                          <Avatar sx={{ width: 35, height: 35 }}>
                            {`${authed?.firstName
                              ?.substring(0, 1)
                              ?.toUpperCase()} ${authed?.lastName
                              ?.substring(0, 1)
                              ?.toUpperCase()}`}
                          </Avatar>
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={openMenu}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem onClick={() => handleNavigation("profile")}>
                            <Avatar />{" "}
                            {`${authed?.firstName} ${authed?.lastName}`}
                          </MenuItem>
                          <Divider />
                          {(authed?.roleId ?? 0) > 1 && (
                            <MenuList>
                              <MenuItem
                                onClick={() => handleNavigation("users")}
                              >
                                <ListItemIcon>
                                  <PersonAdd fontSize="small" />
                                </ListItemIcon>
                                Manage Members
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleNavigation("ilts")}
                              >
                                <ListItemIcon>
                                  <MeetingRoomIcon fontSize="small" />
                                </ListItemIcon>
                                Manage ILTS
                              </MenuItem>
                            </MenuList>
                          )}
                          <MenuItem onClick={() => handleLogout()}>
                            <ListItemIcon>
                              <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Disclosure>
          <main>
            <Outlet></Outlet>
          </main>
        </React.Fragment>
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </main>
  );
};
