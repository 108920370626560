import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordService } from "./services/forgot-password.service";

type Step = 1 | 2 | 3;

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [step, setStep] = useState<Step>(1);

  const [error, setError] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [resendCooldown, setResendCooldown] = useState<number>(0);

  const forgotPasswordService = ForgotPasswordService.Instance;
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendCooldown > 0) {
      timer = setTimeout(() => setResendCooldown(resendCooldown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCooldown]);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setConfirmPassword(e.target.value);
  };

  const validateEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);
  const validateOtp = (otp: string): boolean => /^\d{6}$/.test(otp);
  const validatePassword = (): boolean => newPassword.length >= 6 && newPassword === confirmPassword;

  const sendOtpApi = async (email: string): Promise<void> => {
    setLoading(true);
    try {
      const response = await forgotPasswordService.sendOtp(email)
      if(response){
        setStep(2);
      }
      setResendCooldown(30); // Start 30 seconds cooldown for resend
    } catch (error) {
      setError('Failed to send OTP. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const verifyOtpApi = async (otp: string): Promise<void> => {
    setLoading(true);
    try {
      const response = await forgotPasswordService.verifyOtp(email, otp)
      if(response){
        setStep(3);
      }
    } catch (error) {
      setOtpError('Invalid OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const resetPasswordApi = async (password: string): Promise<void> => {
    setLoading(true);
    try {
      const response = await forgotPasswordService.resetPassword(email, otp, password)
      if(response){
        alert('Password reset successful!');
        navigate('/');
      }
    } catch (error) {
      setPasswordError('Failed to reset password. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSendOtp = (): void => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email.');
      return;
    }
    setError('');
    sendOtpApi(email);
  };

  const handleVerifyOtp = (): void => {
    if (!validateOtp(otp)) {
      setOtpError('OTP should be a 6-digit number.');
      return;
    }
    setOtpError('');
    verifyOtpApi(otp);
  };

  const handleResetPassword = (): void => {
    if (!validatePassword()) {
      setPasswordError('Passwords must match and be at least 6 characters.');
      return;
    }
    setPasswordError('');
    resetPasswordApi(newPassword);
  };

  const handleResendOtp = (): void => {
    if (resendCooldown === 0) {
      sendOtpApi(email);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5, p: 3, border: '1px solid #ddd', borderRadius: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Forgot Password
      </Typography>

      {step === 1 && (
        <>
          <TextField
            label="Email Address"
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            margin="normal"
            error={Boolean(error)}
            helperText={error}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendOtp}
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Send OTP'}
          </Button>
        </>
      )}

      {step === 2 && (
        <>
          <TextField
            label="Enter OTP"
            type="text"
            value={otp}
            onChange={handleOtpChange}
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 6 }}
            error={Boolean(otpError)}
            helperText={otpError}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyOtp}
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Verify OTP'}
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={handleResendOtp}
            fullWidth
            sx={{ mt: 1 }}
            disabled={resendCooldown > 0 || loading}
          >
            {resendCooldown > 0 ? `Resend OTP (${resendCooldown}s)` : 'Resend OTP'}
          </Button>
        </>
      )}

      {step === 3 && (
        <>
          <TextField
            label="New Password"
            type="password"
            value={newPassword}
            onChange={handleNewPasswordChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            fullWidth
            margin="normal"
            error={Boolean(passwordError)}
            helperText={passwordError}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleResetPassword}
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Reset Password'}
          </Button>
        </>
      )}
    </Box>
  );
};

export default ForgotPassword;
