import { environment } from "../../../environment/environment";
import { HTTP_METHODS } from "../../common/common.constants";
import { CommonService } from "../../common/services/common.service";

export class ForgotPasswordService {
  private _baseUrl = environment.iltBaseApiUrl;
  private serviceURL = `${this._baseUrl}`;
  private static _instance: ForgotPasswordService;

  public static get Instance(): ForgotPasswordService {
    return this._instance || (this._instance = new this());
  }

  async sendOtp(email: string): Promise<any[]> {
    let result:any
    let payload = {
      email: email
    }

    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this.serviceURL}/send-otp`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async verifyOtp(email: string, otp: string): Promise<any[]> {
    let result:any
    let payload = {
      email: email,
      otp: otp
    }

    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this.serviceURL}/verify-otp`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async resetPassword(email: string, otp: string, password: string): Promise<any[]> {
    let result:any
    let payload = {
      email: email,
      otp: otp,
      password: password
    }

    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this.serviceURL}/reset-password`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response, true);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

}
