import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../services/auth.service";
import React, { useState } from "react";
import { UserLogin } from "./common/models/user-account.model";
import { CommonUtils } from "./common/services/common-utils";
import { MessageAlert } from "./common/components/MessageAlert";
import { ComponentState } from "./common/models/component-state.model";
import { BusyIndicator } from "./common/components/BusyIndicator";
import { Navigate } from "react-router-dom";
import { Box, Button, Input } from "@mui/material";
import { ConfirmDialog } from "./common/components/ConfirmDialog";
import { getCookie, setCookie } from 'typescript-cookie'
import { isExpired, decodeToken } from "react-jwt";
import authChannel from "../Broadcast";

interface LoginPageProps {
}

interface LoginPageState extends ComponentState {
  openConfirm: boolean;
  emailId: string;
  confirmType: 'email' | 'password'
  userMessage?: string
}

export default class LoginPage extends React.Component<LoginPageProps, LoginPageState> {

  private authService: AuthService;
  private userAuthorized = false;

  constructor(props: LoginPageProps) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.authService = AuthService.Instance;
    this.state = { isLoading: false, messageAlert: undefined, openConfirm: false, emailId: '', confirmType: 'email' }
  }
  checkLogin(accessToken:string){
    if(accessToken){
      this.authService.isAlreadyLoggedIn(accessToken).then(res=>{
        this.setState({isLoading:false});
        if(res){
          this.isuserAlreadyAuth=true;
          this.authService.lookUpData();
          localStorage.setItem('grantId',res.grantId.toString())
          setCookie('access_token',res.accessToken, { path: '/' })
        }
      }).catch((err) => {
        console.log(err,'error in isLoggedin')
      });
    }
  }
  private isuserAlreadyAuth = false;
  componentDidMount() {
    this.isuserAlreadyAuth=false
    this.userAuthorized=false
    const accessToken = getCookie('access_token');
    if (accessToken) {
      const payload = decodeToken(accessToken);
      this.forceUpdate();
      this.authService.isAlreadyLoggedIn(accessToken).then(res=>{
        this.setState({isLoading:false});
        if(res){
          this.isuserAlreadyAuth=true;
          this.authService.lookUpData();
          localStorage.setItem('grantId',res.grantId.toString())
          setCookie('access_token',res.accessToken, { path: '/' })
        }
      }).catch((err) => {
        console.log(err,'error in isLoggedin')
      });
    }
    authChannel.onmessage=(e)=>{
      if(e.data.accessToken){
        this.checkLogin(e.data.accessToken)
      }
    }
  }

  validationSchema() {
    return Yup.object().shape({
      userName: Yup.string().required("This field is required!"),
      password: Yup.string().required("This field is required!"),
    });
  }
  handleLogin(formValue: UserLogin) {
    if (!formValue?.userName || !formValue?.password) return; 

    this.setState({ isLoading: true });
    this.authService.login(formValue).then(res => {
      this.setState({ isLoading: false });
      if (res) {
        this.userAuthorized = true;
        this.authService.lookUpData();
        localStorage.setItem('grantId',res.grantId.toString())
        setCookie('access_token',res.accessToken, { path: '/' })
        authChannel.postMessage({type:'login',accessToken:res.accessToken})
      }
    })
      .catch((err) => {
        this.setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
      });
  }
  
  handleForgotPassword = () => {
    if (!this.state.emailId) {
      this.setState({ messageAlert: CommonUtils.setErrorMessage('Please fill in the email field') });
      return;
    }
  
    this.setState({ isLoading: true });
  
    this.authService.forgotPassword(this.state.emailId)
      .then((res) => {
        this.setState({ isLoading: false, userMessage: res.userMessage, confirmType: 'password' });
      })
      .catch((err) => {
        this.setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
      });
  }
  

  render() {
    const initialValues: UserLogin = {
      userName: "",
      password: "",
    };
    if (this.isuserAlreadyAuth) {
      const path = localStorage.getItem('path')
      return <Navigate to={`/${path}`} replace={true} />;
    }
    return (
      <div className="w-96">
        <MessageAlert message={this.state?.messageAlert}></MessageAlert>
        <BusyIndicator loading={this.state?.isLoading}></BusyIndicator>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <Formik
            initialValues={initialValues}
            validationSchema={this.validationSchema}
            onSubmit={this.handleLogin}
          >
            <Form>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Username
                </label>
                <Field name="userName" type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                <ErrorMessage
                  name="userName"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Password
                </label>
                <Field name="password" type="password" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>

              <div className="flex items-center justify-center">
                <button type="submit" className="bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                  Login
                </button>
                <Button className="inline-block align-baseline font-bold text-sm text-sky-700 hover:text-sky-800" href="/forgot-password">
                  Forgot Password?
                </Button>
              </div>
            </Form>
          </Formik>
          <p className="text-center text-gray-500 text-xs pt-5">
            &copy;2023 ILT. All rights reserved.
          </p>
        </div>
        <ConfirmDialog 
          open={this.state.openConfirm}
          dialogTile="Forgot Password"
          maxWidth="lg"
          isLoading={this.state.isLoading}
          handleClose={()=> this.setState({ openConfirm: false })}
          handleSubmit={this.handleForgotPassword}
          hideSubmit={this.state.confirmType === 'password'}
        >
          <Box component='form' sx={{p: 5}}>
            {
              this.state.confirmType === 'email' &&  <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Enter your Email address
                  </label>
                  <Input 
                    name="userName" 
                    type="email" 
                    value={this.state.emailId}
                    onChange={(event)=> this.setState({emailId: event.target.value})}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>
            }
            {
              this.state.confirmType === 'password' && 
              <div>
                <span>{this.state.userMessage}</span>
              </div>
            }
          </Box>
        </ConfirmDialog>
        {this.userAuthorized && <Navigate to="/dashboard" replace={true} />}
      </div>
    );
  }
}
