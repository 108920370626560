import { Alert, Snackbar } from "@mui/material"
import { IMessageAlertModel } from "../models/message-alert.model";
import { useEffect, useState } from "react";
import { isUndefined } from "lodash";

interface MessageAlertProps {
  message?: IMessageAlertModel;
}

export const MessageAlert = (props: MessageAlertProps) => {
  const [messageAlert, setMessageAlert] = useState<IMessageAlertModel | undefined>(props.message);
  useEffect(() => {
    setMessageAlert(props.message)
  }, [props.message])
  const handleClose = () => {
    setMessageAlert(undefined);
  };
  if(messageAlert!==undefined){
    return (
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!isUndefined(messageAlert)} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageAlert?.status}>{messageAlert?.message}</Alert>
      </Snackbar>
    )
  }
  else{
    return(<></>)
  }
}

