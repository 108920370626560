interface StyledGridOverlayProps {
  message: string;
}

export const StyledGridOverlay = (props: StyledGridOverlayProps) => {
  return (
    <main>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50px', }}>
        {props.message}
      </div>
    </main>
  )
}