

import { Autocomplete, Box, FormControl, Icon, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, TextField } from '@mui/material';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { ILTService } from './services/ilt.service';
import { ILTDetailsModel, ILTPostModel } from './models/ilt.model';
import React from 'react';
import { MessageAlert } from '../common/components/MessageAlert';
import { CommonUtils } from '../common/services/common-utils';
import { BusyIndicator } from '../common/components/BusyIndicator';
import { ComponentState } from '../common/models/component-state.model';
import { ConfirmDialog } from '../common/components/ConfirmDialog';
import AuthService from '../../services/auth.service';
import { UserService } from '../users/user.service';
import { UserModel } from '../common/models/user-account.model';
import { debounce } from '@mui/material/utils';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DropDownModel } from '../common/models/lookup.model';
import { SchoolsModel } from '../common/models/schools.model';
import { USER_ROLES } from '../common/common.constants';
import ReactQuill from 'react-quill';

interface ILTMaintenanceProps {
  iltID?: number;
  openModal: boolean;
  closeModal: any;
}

const ILTMaintenance: FC<ILTMaintenanceProps> = (props) => {
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = React.useState<ComponentState>({ isLoading: false });

  const defaultRowData = {} as ILTDetailsModel;
  const [rowData, setRowData] = useState<ILTDetailsModel>(defaultRowData);

  const defaultPostData = {} as ILTPostModel;
  const [postData, setPostData] = useState<ILTPostModel>(defaultPostData);

  const [userList, setUserList] = useState<UserModel[]>([]);

  const [userDistricts, setUserDistricts] = React.useState<DropDownModel[]>([]);
  const [userSchools, setUserSchools] = React.useState<DropDownModel[]>([]);
  
  const [inputValue, setInputValue] = React.useState('');

  const iltService = ILTService.Instance;
  const userService = UserService.Instance;

  useEffect(() => {
    if(!props.iltID) setPostData(defaultPostData)
    reloadData();
  }, [props.iltID]);

  useEffect(() => {
    getUserDitricts();
  }, []);

  const handleMemberList = (user: UserModel, removeUser: boolean) => {
    if (!rowData.members) {
      rowData.members = [];
    }
    const currentIndex = rowData.members.findIndex(x => x.userId === user.userId);
    if (removeUser) {
      rowData.members.splice(currentIndex, 1);
    } else {
      rowData.members.unshift({ ...user, canRemove: true });
    }

    setRowData({ ...rowData, members: rowData.members })
    setPostData({ ...postData, memberIds: rowData.members.map(m => m.userId) })
  };

  const handleClose = (refresh: boolean) => {
    setRowData(defaultRowData);
    bindPostData(defaultRowData);
    props.closeModal(refresh);
  };

  const handleSubmit = () => {
    if(currentUser?.roleId === 2 ? postData.ownerId = currentUser.userId : '')
    if (!validateMeeting()) return;

    setState({ isLoading: true });
    if (!postData.ownerId && currentUser?.userId) {
      postData.ownerId = currentUser.userId;
    }
    const promiseUser = (postData?.iltId ?? 0) > 0 ? iltService.updateILT(postData) : iltService.createILT(postData);
    promiseUser.then(res => {
      setState({ isLoading: false, messageAlert: CommonUtils.setAlertMessage(res.userMessage, "success") });
      setTimeout(() => handleClose(true), 500);
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    });
  };

  const reloadData = () => {
    if (!props?.iltID) return;
    setState({ isLoading: true });
    iltService.getILTDetails(props.iltID).then(res => {
      setRowData(res);
      bindPostData(res);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });;
    });
  }

  const bindPostData = (data: ILTDetailsModel) => {
    const payload: ILTPostModel = {
      iltId: data?.iltId,
      title: data?.title,
      ownerId: data?.owner?.userId ?? currentUser?.userId,
      ownerEmailId: data?.owner?.emailId,
      description: data?.description,
      schoolId: data?.school?.schoolId,
      memberIds: data?.members?.map(f => f.userId),
      schoolDistrict: data?.school?.schoolDistrict
    };
    setPostData(payload);

    if (payload?.schoolDistrict > 0) {
      getUserDitrictSchools(payload?.schoolDistrict);
    }
  }

  const validateMeeting = (): boolean => {
    const errorMessages: {
      title: string;
      ownerId: string;
      schoolId: string;
      description: string;
      memberIds: string;
    } = {
      title: "Title is required",
      ownerId: "Owner ID is required",
      schoolId: "School ID is required",
      description: "Description is required",
      memberIds: "At least one member is required",
    };
  
    for (const field in errorMessages) {
      if (!postData[field as keyof ILTPostModel]) {
        const errorMessage = errorMessages[field as keyof typeof errorMessages];
        setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(errorMessage) });
        return false;
      }
    }
  
    if (postData.memberIds.length === 0) {
      const errorMessage = "At least one member is required to create ILT";
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(errorMessage) });
      return false;
    }
  
    return true;
  };
  
  
  const onAutoCompleteChange = (keyword: string, minRole?: number) => {
    if (!keyword) return;
    // console.log("###", keyword, minRole)
    setState({ isLoading: true });
    userService.searchUsers(keyword, minRole).then(res => {
      setUserList(res);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });;
    });
  }

  const getUserDitricts = () => {
    if (!currentUser?.userId) return;
    setState({ isLoading: true });
    userService.getUserDistrics(currentUser?.userId).then(res => {
      setUserDistricts(res);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    })
  }

  const getUserDitrictSchools = (districtId: number) => {
    setState({ isLoading: true });
    userService.getUserDistricSchools(districtId).then(res => {
      setUserSchools(res);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    })
  }

  const onAutoCompleteInputChange = useMemo(() => debounce((keyword: string, minRole?: number) => onAutoCompleteChange(keyword, minRole), 700), []);

  const customToolbar = [["bold", "italic", "underline"], ["link"]];

  return (
    <React.Fragment>
      <MessageAlert message={state.messageAlert}></MessageAlert>
      <BusyIndicator loading={state?.isLoading}></BusyIndicator>
      <ConfirmDialog
        maxWidth='sm'
        dialogTile={rowData?.iltId > 0 ? 'Edit ILT' : 'Add ILT'}
        open={props.openModal}
        isLoading={state.isLoading}
        handleClose={() => handleClose(false)}
        handleSubmit={handleSubmit}
      >
        {
          <Fragment>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }} noValidate autoComplete="off">
              <TextField 
                id="title-required" 
                label="ILT Name"  
                required 
                value={postData?.title ?? ''}
                onChange={(val) => setPostData({ ...postData, title: val.target.value })} />
              <div className='inline-flex'>
                <Autocomplete
                  id="ilt-owner"
                  noOptionsText="No Users Found"
                  options={userList}
                  sx={{ width: 250 }}
                  disabled={(currentUser?.roleId === USER_ROLES.DIRECTOR || currentUser?.roleId === USER_ROLES.PROJECTLEADER || currentUser?.roleId === USER_ROLES.EVALUATOR)  ? false : true}
                  value={ currentUser?.roleId === 2 ? currentUser :  rowData?.owner ?? "" }
                  onInputChange={(val) => onAutoCompleteInputChange((val?.target as any)?.value, 2)}
                  renderInput={(params) => {
                    return (<TextField 
                      {...params} label="Type owner name..." 
                      value={params.inputProps.value ?? ''} 
                      helperText={postData?.ownerEmailId} 
                    />)}
                  }
                  onChange={(event, value) => {
                    // console.log("rowData?.owner", value)
                    // console.log("rowData?.owner ?? currentUser", rowData?.owner , currentUser)
                    if (value) {
                      setPostData({ ...postData, ownerId: value?.userId, ownerEmailId: value?.emailId })
                      setRowData({...rowData, owner: value })
                  }}}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : `${option.firstName} ${option.lastName} - ${option.emailId}`
                  }
                />
              </div>
              <FormControl>
                <TextField 
                  id="districtId-select" 
                  label="Districts" required select value={postData?.schoolDistrict ?? 0}
                  onChange={(val) => {
                    setPostData({ ...postData, schoolDistrict: +val.target.value });
                    getUserDitrictSchools(+val.target.value);
                  }}>
                  {userDistricts?.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <TextField id="schoolId-select" label="School" required select value={postData?.schoolId ?? 0}
                onChange={(val) => setPostData({ ...postData, schoolId: +val.target.value })}>
                {userSchools?.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <div className="h-36 w-[98%] px-3 pl-2 py-1 mb-10 pr-4">
                <span className=" text-gray-500 ">Description</span> 
                <ReactQuill
                  id="description-required"
                  theme="snow"
                  value={postData?.description ?? ''}
                  onChange={(value) => {
                    if((postData?.description != value && rowData.iltId !== undefined) || props.iltID === undefined ) {
                      setPostData({ ...postData, description: value })
                  }}}
                  modules={{
                    toolbar: customToolbar,
                  }}
                  className='h-24'
                />
              </div>
            </Box>
            <div>
              <Autocomplete
                id="ilt-members"
                noOptionsText="No Users Found"
                options={userList.filter(user => 
                  !(rowData.members ?? []).some(member => member.userId === user.userId)
                )}
                inputValue={inputValue}
                className='pl-2 mr-7'
                onInputChange={(event,val) => {
                  setInputValue(val)
                  if(val.split('-').length>1){
                    setInputValue('')
                  }
                  onAutoCompleteInputChange(val)
                }}
                renderInput={(params) => <TextField {...params} label="Start typing member name..." sx={{
                  bgcolor: 'background.paper',
                  boxShadow: 1,
                  borderRadius: 2
                }} />
                }
                onChange={(event, value) => {
                  if (value)
                    handleMemberList(value, false);
                }}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : `${option.firstName} ${option.lastName} -  ${option.emailId}`
                }
              />
              <List dense={false} >
                {rowData?.members?.map((option, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Fragment>
                        <IconButton aria-label="edit" onClick={() => handleMemberList(option, true)} key={`icon-button-${index}`}>
                          <RemoveCircleOutlineIcon key={`icon-${index}`} />
                        </IconButton>
                      </Fragment>
                    }
                  >
                    <ListItemText primary={`${option.firstName} ${option.lastName} - ${option.emailId}`} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Fragment>
        }
      </ConfirmDialog>
    </React.Fragment >
  )
};

export default ILTMaintenance;

