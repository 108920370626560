
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { ILTService } from './services/ilt.service';
import { Button, Icon } from '@mui/material';
import ILTMaintenance from './ilt-maintenance';
import { MessageAlert } from '../common/components/MessageAlert';
import { CommonUtils } from '../common/services/common-utils';
import { BusyIndicator } from '../common/components/BusyIndicator';
import { ComponentState } from '../common/models/component-state.model';
import AuthService from '../../services/auth.service';
import { StyledGridOverlay } from '../common/components/StyledGridOverlay';
import { USER_ROLES } from '../common/common.constants';

interface ILTListProps { }

const ILTList: FC<ILTListProps> = () => {
  const [iltList, setILTList] = useState<GridRowsProp>([]);
  const [iltID, setILTID] = React.useState<number | undefined>(undefined);
  const [state, setState] = React.useState<ComponentState>({ isLoading: false });
  const [open, setOpen] = React.useState(false);
  const currentUser = AuthService.getCurrentUser();

  const columns: GridColDef[] = [
    { field: 'ownerName', headerName: 'Owner Name', flex: 1, sortable: false, disableColumnMenu: true },
    { field: 'title', headerName: 'Title', flex: 1, sortable: false, disableColumnMenu: true },
    { field: 'description', headerName: 'Description', flex: 1, sortable: false, disableColumnMenu: true, 
    renderCell: (params) => {
      return (
        <iframe
          srcDoc={params.value}
          style={{ width: "100%", height: 50, border: "none" }}
        ></iframe>
      );
    }, }
  ];

  columns.push({
    field: "",
    disableColumnMenu: true,
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return (
        <Fragment>
          {((currentUser?.userId === params?.row?.ownerId) || currentUser?.roleId === USER_ROLES.DIRECTOR) &&
            <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
              <Button aria-label="delete" color="primary" onClick={() => handleConfirmModal(params?.row?.iltId)}>
                <Icon baseClassName="fas" className="fa-pen" fontSize="small" />
              </Button>
            </div>
          }
        </Fragment>
      );
    }
  });

  const handleClose = (refresh: boolean) => {
    setOpen(false);
    setILTID(0);
    if (refresh) {
      reloadData();
    }
  };

  const iltService = ILTService.Instance;
  useEffect(() => {
    reloadData();
  }, []);

  const handleConfirmModal = (iltId?: number) => {
    setILTID(iltId);
    setOpen(true);
  }

  const reloadData = () => {
    setState({ isLoading: true });
    iltService.getILTList().then(res => {
      const rows = res?.map((m, index) => {
        return { id: index, ...m }
      });
      setILTList(rows);
      setState({ isLoading: false });
    }).catch((err) => {
      setState({ isLoading: false, messageAlert: CommonUtils.setErrorMessage(err) });
    })
  }

  return (
    <React.Fragment>
      <MessageAlert message={state.messageAlert}></MessageAlert>
      <BusyIndicator loading={state?.isLoading}></BusyIndicator>
      <header className="bg-white shadow">
        <div className="mx-auto p-2">
          <h1 className="text-xl font-bold tracking-tight text-gray-900">ILT Maintenance</h1>
        </div>
      </header>

      <div className="mx-auto py-6">
        {(currentUser?.roleId ?? 0) > 1 &&
          <div className='text-right py-2'>
            <Button variant="outlined" onClick={() => handleConfirmModal()}> Add ILT </Button>
          </div>
        }
        <div>
          <DataGrid
            autoHeight
            rowSelection={false}
            rows={iltList}
            columns={columns}
            pageSizeOptions={[5, 10, 15, 20, 30, 50, 100]}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            slots={{
              noRowsOverlay: () => {
                return (<StyledGridOverlay message="No ILTS"></StyledGridOverlay>)
              }
            }} />
        </div>
      </div>
      <ILTMaintenance iltID={iltID} openModal={open} closeModal={(ev: boolean) => handleClose(ev)} />
    </React.Fragment>
  );
};

export default ILTList;